.dashboard {
    /* height: 100vh; */
    /* width: 95vw; */
    overflow: hidden;
    /* background-color: #333; */
    /* display: flex; */
    /* align-items: center; */
    padding-top: 1vw;
    padding-bottom: 1vw;
    text-align: center;
    justify-content: center;
    position: relative;
  }
  
  /* .dashboard__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #dcdcdc;
    padding: 30px;
  } */
  
  .dashboard__btn {
    padding: 10px;
    /* margin-top: 5px; */
    float: right;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #333;
    cursor: pointer;
  }
  
  .dashboard div {
    padding: 10px;
    /* margin-top: 5px; */
    float: left;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    color: #f2f2f2;
  }

  .dashboard img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.producer {
  padding: "2vw 10vw";
}