.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-direction: column;
}
  
.logo {
  position: relative;
  /* height: 40vh; */
}

.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.login__btn {

  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
  cursor: pointer;
}

.login__google {
  background-color: #4285f4;
  cursor: pointer;
}

.login div {
  margin-top: 7px;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
