/* additional css elements put here */

.accountIcon {
  width: 30em;
  border-radius: 50%;
}

/* highlight settings */
em {
  background-color: yellow;
  color: black;
}

iframe {
  border: 0px;
}

button {
  border-radius: 12px;
}

button:disabled {
  background: #f5f5f5;
  color: #c3c3c3;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.free {
  pointer-events: 'auto';
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  /* border: solid black; */
  /* position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10vh; */
  /* margin-left: -10vw; */
  /* width: 100px;
  height: 100px; */
}

.free-text {
  pointer-events: 'auto';
  font-weight: bolder;
  font-size: 20px;
}

.button {
  display: inline-block;
  padding: 15px 20px;
  border-radius: 3px;
  font-size: 15px;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  text-decoration: none !important;
  margin-right: 5vw;  
  white-space: nowrap;
  border: none;
  -webkit-appearance: none;
  cursor: pointer !important;
}

.schedule {
  background-color: #333;
  color: #fff !important;
}

.chat {
  color: #000;
  background: #fff;
  border: #000;
}